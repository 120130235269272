import { SyntheticEvent, useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { useReactAdminFilters } from 'src/hooks/useReactAdminFilters';
import { extractIdFromIri } from 'src/utils/extractIdFromIri';

export interface SelectableItem {
  id: string;
  [key: string]: string;
}

export interface UseAutocompleteFilterParams {
  resource: string;
  filterKey: string;
  filterValue: string;
  itemLabel: string;
}

export function useAutocompleteFilter<T extends SelectableItem>({
  resource,
  filterKey,
  filterValue,
  itemLabel,
}: UseAutocompleteFilterParams) {
  const [items, setItems] = useState<T[]>([]);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const { filterValues, setFilters } = useReactAdminFilters();
  const selectedItemsFromReactAdmin = filterValues[filterKey] as string[];

  const { data, isLoading } = useGetList<T>(resource, {
    pagination: { page: 1, perPage: 1000000 },
    filter: {}, // Ajoutez vos autres filtres ici si nécessaire
  });
  useEffect(() => {
    if (!isLoading && data) {
      const uniqueItems = data.filter(
        (item, index, self) => index === self.findIndex((t) => t[itemLabel] === item[itemLabel])
      );

      setItems(uniqueItems);
    }
  }, [data, isLoading, itemLabel]);

  useEffect(() => {
    setSelectedItems(
      items.filter((item) => {
        const fieldValueData =
          filterValue === 'id' ? extractIdFromIri(item[filterValue]) : item[filterValue];
        return (
          typeof fieldValueData === 'string' &&
          selectedItemsFromReactAdmin?.includes(fieldValueData)
        );
      })
    );
  }, [filterValue, items, selectedItemsFromReactAdmin]);

  const handleChange = (event: SyntheticEvent, value: T[]) => {
    const itemsFilterValue = value.map((item) => {
      const field = filterValue === 'id' ? extractIdFromIri(item[filterValue]) : item[filterValue];
      return typeof field === 'string' ? field : '';
    });
    setSelectedItems(value);
    setFilters(
      {
        ...filterValues,
        [filterKey]: itemsFilterValue,
      },
      {}
    );
  };

  const handleDelete = (selectedId: string) => {
    const newSelectedItems = selectedItems.filter((selectedItem) => selectedItem.id !== selectedId);
    setSelectedItems(newSelectedItems);
    setFilters(
      {
        ...filterValues,
        [filterKey]: newSelectedItems.map((newSelectedItem) =>
          extractIdFromIri(newSelectedItem.id)
        ),
      },
      {}
    );
  };
  return {
    items,
    selectedItems,
    handleChange,
    handleDelete,
    isLoading,
  };
}
